import React from "react";
import styled from "styled-components";

const Cover = props => (
    <CoverWrapper style={props.style}>
        <div className="container-fluid">
            <CoverContainer>
                {props.children}
            </CoverContainer>
        </div>
    </CoverWrapper>
);
const CoverWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgb(14,28,73);
  overflow: hidden;
  .container-fluid {
    position: relative;
    height: 100%;
  }
  @media(max-width: 768px) {
    height: calc(100vh - 56px);
  }
`;
const CoverContainer = styled.div`
  height: 100%;
  h1.heading1 {
    font-size: 16px !important;
    letter-spacing: 1.1px !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    opacity: 0.5 !important;
    color: #FFF !important;
  }
  h1.heading2 {
    margin-top: 10px;
  }
  @media(min-width: 1200px) {
    h1.heading2 {
      font-size: 3.75rem;
    } 
  }
  h1 {
    color: #ffffff;
    font-family: var(--primary-font);
    font-weight: normal;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.27;
    letter-spacing: -1px;
  }
  h3, h4, h5 {
    color: #ffffff;
    font-family: var(--primary-font);
    font-weight: 300;
  }
  h4 {
    font-size: 1.5vw;
    color: #ffffff;
    font-family: var(--primary-font);
    font-weight: 300;
    text-transform: uppercase;
    padding-left: 1.5rem;
    text-align: left;
  }
  p {
    font-weight: normal;
    font-size: 22px;
    font-family: var(--secondary-font);
    max-width: 60vw;
    text-align: left;
    color: #ffffff;
    line-height: 40px;
  }
  .btn {
    font-family: var(--primary-font);
  }
  #cover-logo {
    width: 18rem;
  }
  #devices {
    display: block;
  }
  @media(max-width: 768px) {
    #devices {
      display: none;
      width: 100%;
      max-width: 600px;
    }
  }
  .cover-background-text {
    position:absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    opacity: 0.02;
    font-size: calc(5rem + 15.33vw);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cover-background-text-container {
      
    }
  }
  
  .cover-background-text h1 {
    margin: 0;
    font-size: calc(5rem + 7.33vw);
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
  }
  @media(min-width: 1200px) {
    .cover-background-text h1 {
      font-size: 9rem;
    }
  }
  @media(max-width: 768px) {
    .cover-background-text {
      margin-top: -80px;
      justify-content: center;
      h1 {
        text-align: center;
      }
    }
  }
  #illustration {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: -1;
    max-width: 50vw;
  }
  .cover-content {
    text-align: center;
    z-index: 3;
    position: relative;
    .btn {
        margin-top: 30px;
    }
  }
  .particles-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cover-image-background {
    display: block;
    width: 960px;
    height: 960px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
    position: absolute;
    left: 50px;
    top: 100px;
    transform-origin: bottom right;
  }
  .cover-image {
    position: relative;
    z-index: 2;
  }
  .cover-image-wrap {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
  @media(min-width: 768px) {
      display: flex;
      justify-content: left;
      align-items: center;
      h1 {
        max-width: 80vw;
      }
      .cover-content {
        margin-top: 10%;
      }
      #cover-logo {
        flex: 1 100%;
        margin-bottom: 2.5rem;
      }
      .cover-image .cover-image-wrap {
        margin-top: 5%;
      }
  }
  @media (min-height: 850px) and (min-width: 992px) {
      .cover-image img {
        transform: scale(0.9) translateX(-25%);
      }
  }
  @media (min-width: 992px) {
    .cover-content {
      margin-top: 7%;  
    }
    .cover-image img {
        transform: scale(0.9);
        transform-origin: top right;
    }
    .btn {
        margin-top: 40px;
    }
  }
  @media (min-width: 1200px) {
    .btn {
        margin-top: 55px;
    }
  }
  @media(max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      margin-top: 60px;
      .cover-content {
        margin-bottom: 40px;
        text-align: center;
      }
      h1, p {
        text-align: center;
        max-width: 100%;
        width: 100%;
      }
   }
   @media(max-width: 1240px) {
      p {
        font-size: 18px;
        line-height: 30px;
      }
  }
  .scroll-indicator {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
  }
  @media(max-width: 768px) {
    .scroll-indicator {
      bottom: 12%;
    }
  }
`;

export default Cover;