import React from "react";
import styled from "styled-components";

const MouseScroll = props => {
    return (
        <MouseScrollWrapper {...props} >
            <div className="scroll-downs">
                <div className="mousey">
                    <div className="scroller" />
                </div>
            </div>
        </MouseScrollWrapper>
    );
};

export default MouseScroll;

const MouseScrollWrapper = styled.div`
  .scroll-downs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :26px;
  height: 34px;
}
.mousey {
  width: 24px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  position:relative;
}
.scroller {
  margin-left: auto;
  margin-right: auto;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(5px); opacity: 1; }
  100% { transform: translateY(12px); opacity: 0;}
}
`;